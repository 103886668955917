import { useCallback } from 'react';
import { OperationResult as UrqlOperationResult, UseMutationState } from 'urql';

import {
  AchieveCampaignMissionByMissionIdMutation,
  AchieveCampaignMissionMutation,
  Exact,
  useAchieveCampaignMissionByMissionIdMutation,
  useAchieveCampaignMissionMutation,
} from '..';

type MutationState = UseMutationState<
  AchieveCampaignMissionMutation,
  Exact<{
    campaignId: string | number;
    achievementCode: string;
  }>
>;

type OperationResult = UrqlOperationResult<
  AchieveCampaignMissionMutation,
  Exact<{
    campaignId: string | number;
    achievementCode: string;
  }>
>;

type MutationByMissionIdState = UseMutationState<
  AchieveCampaignMissionByMissionIdMutation,
  Exact<{
    missionId: string | number;
  }>
>;

type OperationByMissionIdResult = UrqlOperationResult<
  AchieveCampaignMissionByMissionIdMutation,
  Exact<{
    missionId: string | number;
  }>
>;

export const useRecordMissionAchievement = (
  applicationId: string,
): [
  MutationState,
  (campaignId: string, achievementCode: string) => Promise<OperationResult>,
] => {
  const [state, achieveCampaignMissionMutation] =
    useAchieveCampaignMissionMutation();

  const recordMissionAchievement = useCallback(
    async (campaignId: string, achievementCode: string) => {
      return await achieveCampaignMissionMutation(
        {
          applicationId,
          campaignId,
          achievementCode,
        },
        { additionalTypenames: ['CampaignAchievementLog'] },
      );
    },
    [applicationId, achieveCampaignMissionMutation],
  );

  return [state, recordMissionAchievement];
};

export const useRecordMissionAchievementByMissionId = (
  applicationId: string,
): [
  MutationByMissionIdState,
  (missionId: string) => Promise<OperationByMissionIdResult>,
] => {
  const [state, achieveMutation] =
    useAchieveCampaignMissionByMissionIdMutation();

  const recordMissionAchievement = useCallback(
    async (missionId: string) => {
      return await achieveMutation(
        {
          applicationId,
          missionId,
        },
        { additionalTypenames: ['CampaignAchievementLog'] },
      );
    },
    [applicationId, achieveMutation],
  );

  return [state, recordMissionAchievement];
};

export default useRecordMissionAchievement;
