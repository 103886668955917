import NextScript from 'next/script';
import React from 'react';

export type GoogleTagManagerId = `GTM-${string}`;

type Props = {
  googleTagManagerId?: GoogleTagManagerId;
};

export const GoogleTagManager: React.FC<Props> = ({ googleTagManagerId }) => {
  if (!googleTagManagerId) {
    return null;
  }

  const gtmScript = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${googleTagManagerId}');
  `;

  return (
    <NextScript
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: gtmScript,
      }}
    />
  );
};
