export enum NetworkErrorType {
  FAILED_TO_FETCH = '[Network] Failed to fetch',
  LOAD_FEILED = '[Network] Load failed',
}

export const networkErrorMessage: Record<string | NetworkErrorType, string> = {
  [NetworkErrorType.FAILED_TO_FETCH]:
    'リクエストに失敗しました。ネットワーク環境を確認の上、画面をリロードしてみてください',
  [NetworkErrorType.LOAD_FEILED]:
    'リクエストに失敗しました。ネットワーク環境を確認の上、画面をリロードしてみてください',
};
