export enum GiftCodeErrorType {
  NOT_FOUND_GIFT_CODE = 'NOT_FOUND_GIFT_CODE',
  GIFT_CODE_ALREADY_USED = 'GIFT_CODE_ALREADY_USED',
}

export const giftCodeErrorMessage: Record<string | GiftCodeErrorType, string> =
  {
    [GiftCodeErrorType.NOT_FOUND_GIFT_CODE]: '入力されたコードは誤りがあります',
    [GiftCodeErrorType.GIFT_CODE_ALREADY_USED]:
      '入力されたコードは使用済みです',
  };
