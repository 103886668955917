import { Client, createClient } from '@urql/core';
import { authExchange } from '@urql/exchange-auth';
import { GraphQLErrorExtensions } from 'graphql';
import { withUrqlClient } from 'next-urql';
import {
  cacheExchange,
  CombinedError,
  debugExchange,
  errorExchange,
  fetchExchange,
  Operation,
} from 'urql';

import { ApiType, getApiEndpoint, getCollectionHeaders } from './env';
import { clearStoredToken, getStoredToken, storeAuthToken } from './store';
import { getIdToken, isExpiredToken } from './token';

export type ErrorInfo = {
  code: string;
  message?: string;
  path?: readonly (string | number)[];
  extensions?: GraphQLErrorExtensions;
};

export const pickGraphqlCode = (error?: CombinedError): string | undefined => {
  const info = pickGraphqlError(error);
  return info?.code;
};

export const pickGraphqlError = (
  error?: CombinedError,
): ErrorInfo | undefined => {
  if (!error) {
    return undefined;
  }

  const { graphQLErrors } = error;
  const gqlError = graphQLErrors[0];
  if (!gqlError) {
    return undefined;
  }

  const code = gqlError.extensions?.['code'] as string;
  return {
    code,
    message: gqlError?.message,
    path: gqlError?.path,
    extensions: gqlError?.extensions,
  };
};

export const pickNetworkError = (
  error?: CombinedError,
): ErrorInfo | undefined => {
  if (!error) {
    return undefined;
  }

  const { networkError } = error;
  if (!networkError) {
    return undefined;
  }

  return {
    code: error.message,
    message: error.stack,
  };
};

const collectionAuthExchange = authExchange(async (utils) => {
  return {
    addAuthToOperation(operation) {
      const token = getStoredToken();
      if (!token) {
        return operation;
      }

      return utils.appendHeaders(operation, getCollectionHeaders(token));
    },
    willAuthError(_operation) {
      const token = getStoredToken();
      return isExpiredToken(token);
    },
    didAuthError(error, _operation) {
      return error.graphQLErrors.some(
        (e) => e.extensions?.['code'] === 'UNAUTHORIZED',
      );
    },
    async refreshAuth() {
      const refreshedToken = await getIdToken(true);
      if (refreshedToken) {
        storeAuthToken(refreshedToken);
      } else {
        clearStoredToken();
      }
    },
  };
});

// const createSubscriptionClient = () => {
//   const wsClient = createWSClient({
//     url: getApiEndpoint('wallet').replace('http', 'ws'),
//     connectionParams: async () => {
//       const token = await getIdToken();
//       return {
//         headers: getCollectionHeaders(token),
//       };
//     },
//     webSocketImpl: WebSocket,
//   });

//   return subscriptionExchange({
//     forwardSubscription: (operation) => ({
//       subscribe: (sink) => ({
//         unsubscribe: wsClient.subscribe(operation, sink),
//       }),
//     }),
//   });
// };

export const withApiClient = (type: ApiType = 'wallet') => {
  return withUrqlClient(
    () => ({
      url: getApiEndpoint(type),
      exchanges: [
        collectionAuthExchange,
        cacheExchange,
        fetchExchange,
        debugExchange,
        // createSubscriptionClient(),
      ],
    }),
    { ssr: false },
  );
};

export const createUrqlClient = (
  onError: (error: CombinedError, operation: Operation) => void,
): Client => {
  const globalErrorExchange = errorExchange({
    onError,
  });

  return createClient({
    url: getApiEndpoint(),
    exchanges: [
      globalErrorExchange,
      collectionAuthExchange,
      cacheExchange,
      fetchExchange,
      debugExchange,
      // createSubscriptionClient(),
    ],
  });
};

export const createUrqlSSRClient = (
  token?: string,
  type: ApiType = 'admin',
): Client => {
  return createClient({
    url: getApiEndpoint(type),
    fetchOptions: {
      headers: getCollectionHeaders(token),
    },
    exchanges: [cacheExchange, fetchExchange, debugExchange],
  });
};
