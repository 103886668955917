import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type ContextState = {
  isPageTransition?: boolean;
  isLoading?: boolean;
};

type Context = {
  context: ContextState;
  setContext: Dispatch<SetStateAction<ContextState>>;
};

const initialContext = {
  isPageTransition: false,
  isLoading: true,
};

const AnimateContext = createContext<Context | undefined>(undefined);

const AnimateContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [context, setContext] = useState<ContextState>({
    ...initialContext,
  });

  return (
    <AnimateContext.Provider
      value={{
        context,
        setContext,
      }}
    >
      {children}
    </AnimateContext.Provider>
  );
};

function useAnimateContext() {
  const { context } = useContext(AnimateContext) ?? {};
  return context;
}

function useTogglePageTransition() {
  const { setContext } = useContext(AnimateContext) ?? {};

  const togglePageTransition = (value: boolean) => {
    if (!setContext) {
      return;
    }

    setContext((prevState: ContextState) => {
      return { ...prevState, isPageTransition: value };
    });
  };

  return togglePageTransition;
}

export { AnimateContextProvider, useAnimateContext, useTogglePageTransition };
