import { FeatureContentItemFragment } from '../schema';

export const getContentTypeUrl = ({
  contentType,
  externalLink,
  dropSlug,
  campaignSlug,
}: FeatureContentItemFragment) => {
  switch (contentType) {
    case 'CAMPAIGN_SLUG': {
      return `/special/${campaignSlug}`;
    }
    case 'DROP_SLUG': {
      return `/mint/${dropSlug}`;
    }
    case 'EXTERNAL_LINK': {
      return externalLink;
    }
  }
};
