import { isAfter } from '@collection-platform-frontend/shared';
import { useCallback, useMemo } from 'react';

import {
  CampaignAchievementLogListFragment,
  CampaignDetailFragment,
  CampaignErrorType,
  pickGraphqlCode,
  useGetCampaignAchievementLogsQuery,
  useGetCampaignDetailQuery,
} from '..';
import {
  AchievementIconData,
  CampaignData,
  ExchangeItemData,
  MissionData,
} from './types/mission';
import { useGetCampaignExchangableItems } from './use-campaign-exchangable-items';

type IdMap<T> = {
  [id: string]: T;
};
type BalanceHistory = { balance: number; date: Date };

const ACHIEVEMENT_LOGS_QUERY_CONTEXT = {
  additionalTypenames: ['CampaignAchievementLog'],
};

const transformCampaign = (
  campaign?: CampaignDetailFragment,
): CampaignData | undefined => {
  if (!campaign) return undefined;

  return {
    ...campaign,
    startAt: new Date(campaign.startAt),
    endAt: new Date(campaign.endAt),
  };
};

const transformMissions = (
  achievedMissionIdMap: IdMap<Date>,
  missions?: CampaignDetailFragment['campaignMissions'],
): MissionData[] => {
  if (!missions) return [];

  return missions.map((mission) => {
    return {
      ...mission,
      startAt: new Date(mission.startAt),
      endAt: new Date(mission.endAt),
      achievedAt: achievedMissionIdMap[mission.id] ?? undefined,
    };
  });
};

const transformAchievementIcons = (
  pointBalanceHistories: { balance: number; date: Date }[],
  icons?: CampaignDetailFragment['campaignAchievementIcons'],
): AchievementIconData[] => {
  if (!icons) return [];

  return icons.map((icon) => {
    const achievedHistory = pointBalanceHistories.find(
      (history) => history.balance >= icon.achievementPoint,
    );
    return {
      ...icon,
      achievedAt: achievedHistory?.date ?? undefined,
    };
  });
};

const calculateAchievementPointBalance = (
  achievementLogs?: CampaignAchievementLogListFragment[],
) =>
  achievementLogs
    ? achievementLogs.reduce((result, log) => result + log.achievementPoint, 0)
    : undefined;

export const useMissionPageQuery = (
  hasCustomer: boolean,
  slug: string,
  applicationId: string,
) => {
  const [{ fetching, data, error }, refetchDetail] = useGetCampaignDetailQuery({
    variables: {
      slug,
      applicationId,
    },
    pause: !slug,
    requestPolicy: 'network-only',
  });

  const [
    {
      fetching: customerDataFetching,
      data: customerData,
      error: customerError,
    },
  ] = useGetCampaignAchievementLogsQuery({
    variables: {
      slug,
    },
    pause: !slug || !hasCustomer,
    requestPolicy: 'network-only',
    context: ACHIEVEMENT_LOGS_QUERY_CONTEXT,
  });

  const {
    fetching: campaignExchangeItemsFetching,
    data: campaignExchangeItems,
    error: campaignExchangeItemsError,
    refetch: refetchCampaignExchangeItems,
  } = useGetCampaignExchangableItems({
    campaignId: data?.campaign?.id ?? '',
    hasCustomer,
  });

  const campaignAchievementLogs = useMemo(
    () => customerData?.campaignAchievementLogs,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerData?.campaignAchievementLogs?.length],
  );

  // NOTE: カスタマーが達成したミッションIDを集めたマップ
  // ex. { "0123...": new Date(2023, 1, 1), "4567...": new Date(2023, 1, 1), }
  const missionIdAchievedAtMap = useMemo<IdMap<Date>>(() => {
    if (!campaignAchievementLogs) return {};

    return campaignAchievementLogs.reduce((map, achievementLog) => {
      map[achievementLog.campaignMissionId] = new Date(
        achievementLog.createdAt,
      );
      return map;
    }, {} as IdMap<Date>);
  }, [campaignAchievementLogs]);

  // NOTE: ポイントの残高履歴
  // ex. [{ balance: 1000, date: new Date(2023, 1, 1) }, { balance: 2000, date: new Date(2023, 1, 2) }]
  const achievementPointBalanceHistories = useMemo<BalanceHistory[]>(() => {
    if (!campaignAchievementLogs) return [];

    return campaignAchievementLogs.reduce<BalanceHistory[]>(
      (result, log, index) => {
        const previousBalance = result[index - 1]?.balance ?? 0;
        const balance = previousBalance + log.achievementPoint;

        result.push({ balance, date: new Date(log.createdAt) });
        return result;
      },
      [],
    );
  }, [campaignAchievementLogs]);

  const customerPointBalance = useMemo<number | undefined>(
    () => calculateAchievementPointBalance(campaignAchievementLogs),
    [campaignAchievementLogs],
  );

  const customerExchangedPoint = useMemo<number | undefined>(
    () => campaignExchangeItems.itemExchangeLogsPointSum?.amount,
    [campaignExchangeItems.itemExchangeLogsPointSum],
  );

  // NOTE: APIから取得したデータを扱いやすいように変換・計算する
  const campaign = useMemo<CampaignData | undefined>(
    () => transformCampaign(data?.campaign),
    [data?.campaign],
  );

  const missions = useMemo<MissionData[] | undefined>(
    () => transformMissions(missionIdAchievedAtMap, campaign?.campaignMissions),
    [missionIdAchievedAtMap, campaign?.campaignMissions],
  );

  const achievementIcons = useMemo<AchievementIconData[] | undefined>(
    () =>
      transformAchievementIcons(
        achievementPointBalanceHistories,
        campaign?.campaignAchievementIcons,
      ),
    [achievementPointBalanceHistories, campaign?.campaignAchievementIcons],
  );
  const hasAchievementIcons = achievementIcons && achievementIcons.length > 0;

  const exchangeableBaseItems = useMemo(() => {
    return campaignExchangeItems.campaignExchangeableItems?.edges
      ?.map(({ node }) => {
        return {
          ...node,
          startAt: new Date(node.startAt),
          endAt: new Date(node.endAt),
          exchangedAtByCurrentCustomer: node.exchangedAtByCurrentCustomer
            ? new Date(node.exchangedAtByCurrentCustomer)
            : undefined,
        };
      })
      .sort((a, b) => a.sortIndex - b.sortIndex);
  }, [campaignExchangeItems?.campaignExchangeableItems]);

  const hasExchangeableItems =
    exchangeableBaseItems && exchangeableBaseItems?.length > 0;

  const exchangeableItems = useMemo(() => {
    return exchangeableBaseItems?.filter(
      (item: ExchangeItemData) => !item.isExchangedByCurrentCustomer,
    );
  }, [exchangeableBaseItems]);

  const exchangedItems = useMemo(() => {
    return exchangeableBaseItems?.filter(
      (item: ExchangeItemData) => item.isExchangedByCurrentCustomer,
    );
  }, [exchangeableBaseItems]);

  const isNotFoundCampaign =
    pickGraphqlCode(error) === CampaignErrorType.NOT_FOUND_CAMPAIGN;
  const isExpiredCampaign = isAfter(campaign?.endAt);

  const refetch = useCallback(() => {
    refetchDetail();
  }, [refetchDetail]);

  return {
    campaign,
    missions,
    achievementIcons,
    exchangeableItems,
    exchangedItems,
    customerPointBalance,
    customerExchangedPoint,
    isNotFoundCampaign,
    isExpiredCampaign,
    refetch,
    hasAchievementIcons,
    hasExchangeableItems,
    refetchCampaignExchangeItems,
    fetching: fetching || customerDataFetching || campaignExchangeItemsFetching,
    error: error || customerError || campaignExchangeItemsError,
  };
};

export default useMissionPageQuery;
