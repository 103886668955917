export enum DropErrorType {
  NOT_FOUND_DROP = 'NOT_FOUND_DROP',
  NOT_FOUND_DROP_ITEM = 'NOT_FOUND_DROP_ITEM',
  DROP_EXPIRED_AT_VALIDATION = 'DROP_EXPIRED_AT_VALIDATION',
  DROP_ACQUISITION_LIMIT_COUNT_VALIDATION = 'DROP_ACQUISITION_LIMIT_COUNT_VALIDATION',
  DROP_INVENTORY_QUANTITY_LESS_THAN_ZERO = 'DROP_INVENTORY_QUANTITY_LESS_THAN_ZERO',
}

export const dropErrorMessage: Record<string | DropErrorType, string> = {
  [DropErrorType.NOT_FOUND_DROP]: 'ドロップが見つかりません',
  [DropErrorType.NOT_FOUND_DROP_ITEM]: '配布アイテムが見つかりません',
  [DropErrorType.DROP_EXPIRED_AT_VALIDATION]:
    '入力されたコードは有効期限切れです',
  [DropErrorType.DROP_ACQUISITION_LIMIT_COUNT_VALIDATION]:
    '受け取り上限に達しています',
  [DropErrorType.DROP_INVENTORY_QUANTITY_LESS_THAN_ZERO]: '在庫がありません',
};
