import {
  FastAverageColor,
  FastAverageColorIgnoredColor,
  FastAverageColorResult,
  FastAverageColorRgba,
} from 'fast-average-color';
import { useEffect, useMemo, useState } from 'react';

const colorCache: Record<string, FastAverageColorResult> = {};
const fac = new FastAverageColor();

export const WHITE_COLOR = [255, 255, 255, 255] as FastAverageColorRgba;
export const BLACK_COLOR = [0, 0, 0, 255] as FastAverageColorRgba;

export const useAverageColorFromImageUrl = (
  name: string,
  imageUrl?: string | null,
  options?: {
    ignoreWhite?: boolean;
    ignoreBlack?: boolean;
  },
) => {
  const [analysis, setAnalysis] = useState<boolean>(false);
  const [error, setError] = useState();

  const colorCacheKey = `${name}:${JSON.stringify(options)}`;

  const color = colorCache[colorCacheKey];
  const hasColorCache = !!color;

  const ignoredColors: FastAverageColorIgnoredColor = useMemo(() => {
    const ignored: FastAverageColorIgnoredColor = [];
    if (options?.ignoreWhite) ignored.push(WHITE_COLOR);
    if (options?.ignoreBlack) ignored.push(BLACK_COLOR);

    return ignored;
  }, [options]);

  useEffect(() => {
    if (!imageUrl) {
      return;
    }

    if (hasColorCache || analysis) {
      setAnalysis(true);
      return;
    }

    fac
      .getColorAsync(imageUrl, {
        algorithm: 'simple',
        ignoredColor: ignoredColors,
      })
      .then((color) => {
        colorCache[colorCacheKey] = color;
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setAnalysis(true);
      });

    return () => {
      fac.destroy();
    };
  }, [
    name,
    imageUrl,
    hasColorCache,
    analysis,
    ignoredColors,
    colorCacheKey,
    setError,
  ]);

  return {
    color,
    analysis,
    error,
  };
};
