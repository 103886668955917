export enum DistributionTypeEnum {
  BY_DROP = 'BY_DROP',
  BY_GIFT_CODE = 'BY_GIFT_CODE',
  BY_PURCHASE = 'BY_PURCHASE',
  BY_EXCHANGE_CAMPAIGN_POINT = 'BY_EXCHANGE_CAMPAIGN_POINT',
}

export type DistributionType =
  | DistributionTypeEnum.BY_DROP
  | DistributionTypeEnum.BY_GIFT_CODE
  | DistributionTypeEnum.BY_PURCHASE;

export const isFreeDrop = (type?: string): boolean => {
  return type === DistributionTypeEnum.BY_DROP;
};

export const isGiftCodeDrop = (type?: string): boolean => {
  return type === DistributionTypeEnum.BY_GIFT_CODE;
};

export const isPurchaseDrop = (type?: string): boolean => {
  return type === DistributionTypeEnum.BY_PURCHASE;
};
