export const getEnvironment = (releaseBranch = 'main') => {
  const branchName =
    process.env.VERCEL_GIT_COMMIT_REF ||
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;
  if (branchName === releaseBranch) {
    return 'production';
  }

  if (branchName === 'sandbox') {
    return 'sandbox';
  }

  return 'develop';
};
