import { IncomingMessage } from 'http';
import { GetServerSidePropsContext, PreviewData } from 'next';
import absoluteUrl from 'next-absolute-url';
import { ParsedUrlQuery } from 'querystring';

export const destinationUrl = (
  ctx?: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>,
) => {
  const isSSR = typeof window === 'undefined';
  const origin = isSSR ? absoluteUrl(ctx?.req).origin : window.location.origin;
  const destPath = isSSR ? ctx?.resolvedUrl : window.location.href;
  console.log(isSSR, destPath, origin);
  return destPath && new URL(destPath, origin);
};

export function parse(
  req?: IncomingMessage,
  localhostAddress = 'localhost:3000',
): URL {
  let host =
    (req?.headers ? req.headers.host : window.location.host) ||
    localhostAddress;
  let protocol = /^localhost(:\d+)?$/.test(host) ? 'http:' : 'https:';

  if (
    req &&
    req.headers['x-forwarded-host'] &&
    typeof req.headers['x-forwarded-host'] === 'string'
  ) {
    host = req.headers['x-forwarded-host'];
  }

  if (
    req &&
    req.headers['x-forwarded-proto'] &&
    typeof req.headers['x-forwarded-proto'] === 'string'
  ) {
    protocol = `${req.headers['x-forwarded-proto']}:`;
  }

  const url = req?.url && req?.url.startsWith('/') ? req.url : '';
  return new URL(`${protocol}//${host}${url}`);
}

export function addParams(
  query: ParsedUrlQuery,
  key: string,
  value: string,
): URLSearchParams {
  const searchParam = new URLSearchParams(query as Record<string, string>);

  const param = searchParam.get(key);
  const params = (param?.split(',') ?? []).filter(
    (v) => v !== value && '' !== value,
  );
  searchParam.set(key, [...params, value].join(','));

  return searchParam;
}

export function removeParams(
  query: ParsedUrlQuery,
  key: string,
  value: string,
): URLSearchParams {
  const searchParam = new URLSearchParams(query as Record<string, string>);

  const param = searchParam.get(key);
  const params = (param?.split(',') ?? []).filter(
    (v) => v !== value && '' !== value,
  );
  if (params.length === 0) {
    searchParam.delete(key);
  } else {
    searchParam.set(key, params.join(','));
  }

  return searchParam;
}

export function updateParams(
  query: ParsedUrlQuery,
  key: string,
  value?: string,
): URLSearchParams {
  const searchParam = new URLSearchParams(query as Record<string, string>);
  searchParam.delete(key);

  if (value) {
    searchParam.set(key, value);
  }

  return searchParam;
}

export function clearParams(
  query: ParsedUrlQuery,
  keys: string[],
): URLSearchParams {
  const searchParam = new URLSearchParams(query as Record<string, string>);
  keys.forEach((v) => {
    searchParam.delete(v);
  });

  return searchParam;
}
