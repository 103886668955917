export enum CustomerErrorType {
  CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
  APPLICATION_CUSTOMER_NOT_FOUND = 'APPLICATION_CUSTOMER_NOT_FOUND',
  CUSTOMER_NICKNAME_VALIDATION = 'CUSTOMER_NICKNAME_VALIDATION',
  EXISTS_EMAIL_ADDRESS = 'EXISTS_EMAIL_ADDRESS',
  EXISTS_NICKNAME_OF_CUSTOMER = 'EXISTS_NICKNAME_OF_CUSTOMER',
  FORBIDDEN = 'FORBIDDEN',
  EMAIL_ALREADY_USED = 'EMAIL_ALREADY_USED',
  CHANGE_SAME_EMAIL = 'CHANGE_SAME_EMAIL',
}

export const customerErrorMessage: Record<string | CustomerErrorType, string> =
  {
    [CustomerErrorType.CUSTOMER_NOT_FOUND]: 'ユーザーが見つかりませんでした。',
    [CustomerErrorType.CUSTOMER_NICKNAME_VALIDATION]:
      'ユーザー名に禁止されている文字が使われています。',
    [CustomerErrorType.APPLICATION_CUSTOMER_NOT_FOUND]:
      'ユーザーが見つかりませんでした。',
    [CustomerErrorType.EXISTS_EMAIL_ADDRESS]:
      '入力したメールアドレスは既に使用されています。',
    [CustomerErrorType.EXISTS_NICKNAME_OF_CUSTOMER]:
      '入力したユーザー名は既に使用されています。',
    [CustomerErrorType.FORBIDDEN]: 'ユーザーアクセスが制御されています',
    [CustomerErrorType.EMAIL_ALREADY_USED]:
      '入力したメールアドレスは既に使用されています。',
    [CustomerErrorType.CHANGE_SAME_EMAIL]: '同じメールアドレスです。',
  };
