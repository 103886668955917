import { useRouter } from 'next/router';
import { useMemo } from 'react';

import {
  GetContractsHasDropQuery,
  useGetContractsHasDropQuery,
} from '../graphql/schema';

const compareDate = (
  a: { startAt?: Date | null },
  b: { startAt?: Date | null },
) => {
  const t0 = a.startAt?.getTime() ?? 0;
  const t1 = b.startAt?.getTime() ?? 0;
  if (!t0 && !t1) {
    return 0;
  } else if (!t0 && t1) {
    return 1;
  } else if (t0 && !t1) {
    return -1;
  } else {
    return t1 - t0;
  }
};

export const findDropList = (
  dropItems: GetContractsHasDropQuery['contractsHasDrop'][0]['items'],
) => {
  return dropItems
    .flatMap(({ dropItems }) => {
      return dropItems?.flat() ?? [];
    })
    .filter((d) => !!d)
    .map(({ drop }) => {
      return {
        ...drop,
        startAt: drop?.startAt ? new Date(drop.startAt) : null,
      };
    })
    .sort(compareDate);
};

// データ変換メモ:
// ①contract情報の構造
// const contractItems = [
//   {
//     name
//     items: [
//       {
//         dropItems: [
//           {
//             drop: {
//               startAt,
//             },
//           },
//         ],
//       },
//     ],
//   },
// ];
//
// ②contractに紐つくdrop情報を取得
// const dropList = [
//   { startAt },
//   { startAt }
// ].sort(/** 古い順に並べる、最初に始まるdrop情報がcontractのstartAtとする */)
//
// ③const formatedContractList = [
//   {
//     name,
//     startAt: dropList[0].startAt
//   },
//   {
//     name,
//     startAt: dropList[0].startAt
//   }
// ].sort(/** contractの一番未来から並べる */)

type ContractDropsOption = {
  applicationId: string;
  limit?: number;
};

export const useContractDrops = ({
  applicationId,
  limit,
}: ContractDropsOption) => {
  const { isReady } = useRouter();
  const [{ data: contractsResult, fetching, error }] =
    useGetContractsHasDropQuery({
      pause: !isReady,
      requestPolicy: 'cache-and-network',
      variables: {
        applicationId,
      },
    });
  const contractItems = contractsResult?.contractsHasDrop;

  const contractDrops = useMemo(() => {
    if (!contractItems) {
      return [];
    }

    const contractItemList = contractItems
      .map(({ items, ...contractInfo }) => {
        const dropList = findDropList(items).filter((d) => !!d && !!d.startAt);
        return {
          ...contractInfo,
          drops: dropList,
          startAt: dropList.length > 0 ? dropList[0].startAt : null,
        };
      })
      .sort(compareDate);

    return limit ? contractItemList.slice(0, limit) : contractItemList;
  }, [limit, contractItems]);

  return {
    fetching,
    contractItems,
    contractDrops,
    error,
  };
};
