import { useMemo } from 'react';

export function formatPrice({
  amount,
  currencyCode = 'USD',
  locale = 'en-US',
}: {
  amount: number;
  currencyCode?: string;
  locale?: string;
}): string {
  const formatCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });

  return formatCurrency.format(amount);
}

export function usePrice(
  data?: {
    amount?: number | null;
    currencyCode?: string | null;
    locale?: string;
  } | null,
  useCent = true,
): string {
  const { amount, currencyCode, locale } = data ?? {};
  const value = useMemo(() => {
    if (typeof amount !== 'number' || !currencyCode) {
      return '';
    }

    const v = useCent ? amount / 100 : amount;
    return formatPrice({ amount: v, currencyCode, locale });
  }, [amount, currencyCode, locale, useCent]);

  return value;
}
