import { init, InitConfig } from 'next-firebase-auth';

const sessionAvailableDays = 14 * 60 * 60 * 24 * 1000;

export type AuthOption = Pick<
  InitConfig,
  'appPageURL' | 'authPageURL' | 'loginAPIEndpoint' | 'logoutAPIEndpoint'
>;

export const initAuth = (option: AuthOption = {}) => {
  const {
    appPageURL = '/',
    authPageURL = '/auth',
    loginAPIEndpoint = '/api/login',
    logoutAPIEndpoint = '/api/logout',
  } = option;

  const authOption: InitConfig = {
    debug: process.env.NODE_ENV === 'development',
    appPageURL,
    authPageURL,
    loginAPIEndpoint,
    logoutAPIEndpoint,
    firebaseClientInitConfig: {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY as string,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    },
    cookies: {
      name: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      httpOnly: true,
      maxAge: sessionAvailableDays,
      overwrite: true,
      path: '/',
      sameSite: 'lax',
      secure: process.env.NODE_ENV === 'production',
      signed: true,
    },
  };

  init(authOption);
};
