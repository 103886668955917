import './styles.css';
import 'keen-slider/keen-slider.min.css';

import {
  createUrqlClient,
  CustomerErrorType,
  EnvErrorType,
  initAuth,
  pickGraphqlCode,
} from '@collection-platform-frontend/api';
import {
  GoogleTag,
  GoogleTagManager,
} from '@collection-platform-frontend/shared';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import { useCallback } from 'react';
import { CombinedError, Operation, Provider } from 'urql';

import { authOption } from '../libs/auth-option';
import { getOGPConfig } from '../libs/ogp';
import { useRouter } from '../libs/router';

initAuth(authOption);

const shogakukanGTMId = process.env
  .NEXT_PUBLIC_SHOGAKUKAN_GTM_ID as `GTM-${string}`;
const aniqueGtagId = process.env.NEXT_PUBLIC_ANIQUE_GTAG_ID as `G-${string}`;

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { replace } = useRouter();

  const onError = useCallback(
    (e: CombinedError, _o: Operation) => {
      const errorCode = pickGraphqlCode(e);
      switch (errorCode) {
        case EnvErrorType.UNDER_MAINTENANCE: {
          replace('/maintenance');
          break;
        }
        case CustomerErrorType.FORBIDDEN: {
          replace('/auth/signout');
          break;
        }
        case EnvErrorType.INTERNAL_SERVER_ERROR: {
          replace('/500');
          break;
        }
      }
    },
    [replace],
  );

  const client = createUrqlClient(onError);
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <DefaultSeo {...getOGPConfig()} />
      <GoogleTagManager googleTagManagerId={shogakukanGTMId} />
      <GoogleTag googleTagId={aniqueGtagId} />
      <Provider value={client}>
        <Component {...pageProps} />
        {/* <ErrorNotifier error={error} /> */}
      </Provider>
    </>
  );
};

// const ErrorNotifier: FC<{ error?: ErrorInfo }> = ({ error }) => {
//   const { failure } = useToast();

//   useEffect(() => {
//     if (error?.message) {t
//       failure(error.message);
//     }
//   }, [error, failure]);

//   return <Toaster />;
// };

export default MyApp;
