import { NextRouter, useRouter } from 'next/router';
import { UrlObject } from 'url';

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

type Url = UrlObject | string;

export type RouterWithProxy = NextRouter;

// MEMO: next/routerでは、proxyが更新されないためproxyPathsへの遷移はwindow.locationを使う
export const useRouterWithProxy = (proxyPaths: Url[]): RouterWithProxy => {
  const router = useRouter();

  const push = (
    url: Url,
    as?: Url,
    options?: TransitionOptions,
  ): Promise<boolean> => {
    if (typeof url === 'string' && proxyPaths.includes(url)) {
      window.location.assign(url);
      return Promise.resolve(true);
    }
    return router.push(url, as, options);
  };

  const replace = (
    url: Url,
    as?: Url,
    options?: TransitionOptions,
  ): Promise<boolean> => {
    if (typeof url === 'string' && proxyPaths.includes(url)) {
      window.location.replace(url);
      return Promise.resolve(true);
    }
    return router.replace(url, as, options);
  };

  return {
    ...router,
    push,
    replace,
  };
};
