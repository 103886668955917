import { OperationResult } from 'urql';

import { createUrqlSSRClient, pickGraphqlCode } from '../graphql/client';
import { CampaignErrorType } from '../graphql/errors';
import { GetCampaignDetailDocument } from '../graphql/schema';

const currentApplicationId = process.env
  .NEXT_PUBLIC_X_COLLECTION_APPLICATION_ID as string;

export const getCampaign = async (slug: string) => {
  const client = createUrqlSSRClient(undefined, 'wallet');
  const result = await client
    .query(GetCampaignDetailDocument, {
      slug,

      applicationId: currentApplicationId,
    })
    .toPromise();

  return result;
};

export const isNotFoundCampaignBy = async (slug: string) => {
  const campaignResult = await getCampaign(slug);
  const code = pickGraphqlCode(campaignResult.error);
  return code === CampaignErrorType.NOT_FOUND_CAMPAIGN;
};

export const isNotFoundCampaign = (result: OperationResult) => {
  if (!result) {
    return false;
  }

  const code = pickGraphqlCode(result.error);
  return code === CampaignErrorType.NOT_FOUND_CAMPAIGN;
};
