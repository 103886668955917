import { useCallback, useState } from 'react';

type FormValues = { achievementCode: string };

const INIT_FORM_VALUES: FormValues = {
  achievementCode: '',
};

export const ACHIEVEMENT_CODE_LENGTH = 16;

export const useAchievementCodeForm = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    ...INIT_FORM_VALUES,
  });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const valid = formValues.achievementCode.length === ACHIEVEMENT_CODE_LENGTH;

  const resetFormValues = useCallback(
    () => ({
      ...INIT_FORM_VALUES,
    }),
    [],
  );

  const handleSubmit = useCallback(
    (onSubmit: (formValues: FormValues) => Promise<unknown>) => async () => {
      if (submitting) return;
      if (!valid) {
        setErrorMessage(
          `半角英数字で${ACHIEVEMENT_CODE_LENGTH}文字入力してください`,
        );
        return;
      }

      setSubmitting(true);
      setErrorMessage(undefined);

      try {
        await onSubmit(formValues);
      } finally {
        setSubmitting(false);
      }
    },
    [submitting, valid, formValues],
  );

  return {
    valid,
    submitting,
    formValues,
    errorMessage,
    setFormValues,
    resetFormValues,
    handleSubmit,
    setErrorMessage,
  };
};
