import { AuthOption } from '@collection-platform-frontend/api';
import { destinationUrl } from '@collection-platform-frontend/shared';

export const authOption: AuthOption = {
  appPageURL: ({ ctx }) => {
    const destURL = destinationUrl(ctx);
    const callbackUrl = destURL.searchParams.get('callbackUrl');
    const hasCallbackUrlParams = callbackUrl && callbackUrl.startsWith('/');
    const destPath = hasCallbackUrlParams
      ? decodeURIComponent(callbackUrl)
      : '/';
    return new URL(destPath, origin).toString();
  },
  authPageURL: ({ ctx }) => {
    const destURL = destinationUrl(ctx);
    const ignorePaths = ['/auth/signout'];
    if (ignorePaths.includes(destURL.pathname)) {
      return '/auth';
    }

    return `/auth?callbackUrl=${destURL.pathname}`;
  },
  loginAPIEndpoint: '/api/auth/login',
  logoutAPIEndpoint: '/api/auth/logout',
};
