import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useCallback, useEffect } from 'react';

declare global {
  interface Window {
    gtag: (name: string, value: string, config?: { page_path: string }) => void;
  }
}

export type GoogleTagId = `G-${string}`;

type Props = {
  googleTagId?: GoogleTagId;
};

export const GoogleTag: React.FC<Props> = ({ googleTagId }) => {
  const router = useRouter();

  const handleRouterChange = useCallback(
    (url: string) => {
      if (!googleTagId) {
        return;
      }

      window.gtag('config', googleTagId, {
        page_path: url,
      });
    },
    [googleTagId],
  );

  useEffect(() => {
    if (!googleTagId) {
      return;
    }

    router.events.on('routeChangeComplete', handleRouterChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouterChange);
    };
  }, [router.events, handleRouterChange, googleTagId]);

  if (!googleTagId) {
    return null;
  }

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${googleTagId}`}
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
           gtag('config', '${googleTagId}');
           `,
        }}
      />
    </>
  );
};

export function useGtagEvent() {
  return (eventName: string, eventParams: object) => {
    const gtag = (window as any).gtag;
    if (!gtag) {
      return;
    }
    (window as any).gtag('event', eventName, eventParams);
  };
}
