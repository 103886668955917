import { NextApiRequest, NextApiResponse } from 'next';
import { setAuthCookies } from 'next-firebase-auth';

import { AuthOption, initAuth } from './init';

type LoginResult =
  | {
      status: boolean;
    }
  | {
      error: string;
    };

export function withLoginApi(option?: AuthOption) {
  initAuth(option);

  return async function (
    req: NextApiRequest,
    res: NextApiResponse<LoginResult>,
  ) {
    try {
      await setAuthCookies(req, res);
    } catch (e) {
      console.error(e);
      return res.status(500).json({ error: 'Unexpected error.' });
    }
    return res.status(200).json({ status: true });
  };
}
