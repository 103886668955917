import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const key = 'collection-darkmode';

enum Mode {
  DARK = 'dark',
  LIGHT = 'light',
}

type DarkModeContext = {
  isDarkMode: boolean;
  changeMode: (value: boolean) => void;
};

const initContext = {
  isDarkMode: false,
  changeMode: (_value: boolean) => undefined,
};

const DarkModeContext = createContext<DarkModeContext>(initContext);

export function useDarkModeContext() {
  return useContext(DarkModeContext) ?? initContext;
}

export const DarkModeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isDarkMode, setDarkMode] = useState(false);

  const changeMode = useCallback((isDark: boolean) => {
    setDarkMode(isDark);
    localStorage.setItem(key, isDark.toString());

    document.documentElement.classList.remove(Mode.DARK, Mode.LIGHT);
    if (isDark) {
      document.documentElement.classList.add(Mode.DARK);
    } else {
      document.documentElement.classList.add(Mode.LIGHT);
    }
  }, []);

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

    const onChangeMode = (e: MediaQueryListEvent) => {
      setDarkMode(e.matches);
      localStorage.setItem(key, e.matches.toString());
      changeMode(e.matches);
    };

    mediaQueryList.addEventListener('change', onChangeMode);
    changeMode(localStorage.getItem(key) === 'true');

    return () => {
      mediaQueryList.removeEventListener('change', onChangeMode);
    };
  }, [changeMode]);

  return (
    <DarkModeContext.Provider value={{ isDarkMode, changeMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};
