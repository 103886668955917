const bibiURL = process.env.NEXT_PUBLIC_BIBI_URL as string;

export function generateBibiUrl(privateAssetFileName: string) {
  return `${bibiURL}?book=${processSignedURI(privateAssetFileName)}`;
}

function processSignedURI(privateAssetFileName: string) {
  const uri = `${privateAssetFileName}#filename=book.epub`;
  return encodeURIComponent(uri);
}
