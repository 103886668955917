import { useRouter } from 'next/router';
import { AuthUserContext, useAuthUser } from 'next-firebase-auth';
import { useCallback, useEffect, useMemo } from 'react';
import { OperationContext } from 'urql';

import { pickGraphqlCode } from '../graphql/client';
import { CustomerErrorType } from '../graphql/errors';
import {
  GetMyCustomerQuery,
  useGetMyCustomerQuery,
  useIsExistCustomerQuery,
  useSignUpMutation,
} from '../graphql/schema';

const isCustomerNotFound = (code?: string) => {
  return (
    code === CustomerErrorType.CUSTOMER_NOT_FOUND ||
    code === CustomerErrorType.APPLICATION_CUSTOMER_NOT_FOUND
  );
};

export type CustomerResult = {
  authUser: AuthUserContext;
  customer: GetMyCustomerQuery | undefined;
  fetching: boolean;
  registered: boolean;
  refetchCustomer: (opts?: Partial<OperationContext>) => void;
};

export const useCurrentCustomer = (
  applicationId?: string,
  signup?: boolean,
): CustomerResult => {
  const { isReady } = useRouter();
  const authUser = useAuthUser();

  const firebaseVerified = authUser.emailVerified;
  const [existResult] = useIsExistCustomerQuery({
    pause: !isReady || !firebaseVerified,
    requestPolicy: 'network-only',
    variables: {
      applicationId,
    },
  });

  const isExistCustomer =
    firebaseVerified && (existResult.data?.existCustomer.exist ?? false);

  const [customerResult, refetchCustomer] = useGetMyCustomerQuery({
    pause: !isExistCustomer,
    requestPolicy: 'network-only',
    variables: {
      applicationId,
    },
  });

  const [signUpResult, signUp] = useSignUpMutation();

  const code = pickGraphqlCode(customerResult.error);
  const notFoundCustomer = isCustomerNotFound(code);
  const registered = signUpResult?.data
    ? !notFoundCustomer
    : isExistCustomer && !notFoundCustomer;

  const fetching = useMemo(() => {
    return (
      existResult.fetching || customerResult.fetching || signUpResult.fetching
    );
  }, [existResult.fetching, customerResult.fetching, signUpResult.fetching]);

  const signUpCustomer = useCallback(async () => {
    const idToken = await authUser.getIdToken();
    if (!idToken) {
      return Promise.resolve();
    }

    await signUp({
      input: {
        idToken,
        applicationId,
      },
    }).then(async () => {
      await refetchCustomer();
    });
  }, [signUp, applicationId, authUser, refetchCustomer]);

  useEffect(() => {
    if (!firebaseVerified || !signup || fetching || signUpResult.data) {
      return;
    }

    if (!registered) {
      signUpCustomer();
    }
  }, [
    firebaseVerified,
    signup,
    customerResult,
    signUpResult,
    signUpCustomer,
    fetching,
    registered,
  ]);

  return {
    fetching,
    registered,
    authUser,
    customer: authUser.id ? customerResult?.data : undefined,
    refetchCustomer,
  };
};
