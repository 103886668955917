import { useCallback } from 'react';

import {
  useGetCampaignExchangeableItemsQuery,
  useGetItemExchangeLogsPointSumQuery,
} from '../graphql/schema';

type Args = {
  hasCustomer: boolean;
  campaignId?: string;
};

export const useGetCampaignExchangableItems = ({
  hasCustomer,
  campaignId,
}: Args) => {
  const [
    {
      data: campaignExchangableItems,
      fetching: campaignExchangableItemsFetching,
      error: campaignExchangeItemsError,
    },
    refetchCampaignExchangableItems,
  ] = useGetCampaignExchangeableItemsQuery({
    variables: {
      campaignId: campaignId ?? '',
    },
    pause: !campaignId,
    requestPolicy: 'network-only',
  });
  const items = campaignExchangableItems?.campaignExchangeableItems;

  const [
    {
      data: itemExchangeLogsPointSum,
      fetching: itemExchangeLogsPointSumFetching,
    },
    refetchItemExchangeLogsPointSum,
  ] = useGetItemExchangeLogsPointSumQuery({
    variables: {
      campaignId: campaignId ?? '',
    },
    pause: !campaignId || !hasCustomer,
    requestPolicy: 'network-only',
  });
  const pointSum = itemExchangeLogsPointSum?.itemExchangeLogsPointSum;

  const refetch = useCallback(
    (hasAuth: boolean) => {
      if (campaignExchangableItems) {
        refetchCampaignExchangableItems();
      }

      if (hasAuth && itemExchangeLogsPointSum) {
        refetchItemExchangeLogsPointSum();
      }
    },
    [
      campaignExchangableItems,
      itemExchangeLogsPointSum,
      refetchCampaignExchangableItems,
      refetchItemExchangeLogsPointSum,
    ],
  );

  return {
    data: {
      campaignExchangeableItems: items,
      itemExchangeLogsPointSum: pointSum,
    },
    error: campaignExchangeItemsError,
    fetching:
      campaignExchangableItemsFetching || itemExchangeLogsPointSumFetching,
    refetch,
  };
};
