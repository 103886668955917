import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Client, Context } from 'urql';

import {
  GetCampaignQuizDocument,
  GetCampaignQuizQuery,
  useAchieveCampaignQuizMutation,
  useGetCampaignQuizzesQuery,
} from '../graphql/schema';

export type QuizStatus = 'achieved' | 'locked' | 'challengeable';

export const useCampaignQuizzes = (missionId: string) => {
  const [{ data, fetching, error }] = useGetCampaignQuizzesQuery({
    variables: {
      missionId,
    },
  });

  const total = useMemo(() => data?.campaignQuizzes?.length ?? 0, [data]);
  const progress = useMemo(
    () => data?.campaignQuizzes?.filter((quiz) => quiz.achieved)?.length ?? 0,
    [data],
  );
  const quizzes = useMemo(() => {
    const quizzesWithStatus = [];
    let hasChallengeable = false;
    for (const quiz of data?.campaignQuizzes ?? []) {
      const quizWithStatus = {
        id: quiz.id,
        title: quiz.title,
        status: (quiz.achieved
          ? 'achieved'
          : hasChallengeable
          ? 'locked'
          : 'challengeable') as QuizStatus,
      };
      if (quizWithStatus.status === 'challengeable') {
        hasChallengeable = true;
      }
      quizzesWithStatus.push(quizWithStatus);
    }
    return quizzesWithStatus;
  }, [data]);

  return {
    quizzes,
    total,
    progress,
    fetching,
    error,
  };
};

export const useCampaignQuiz = (
  quizId: string,
  onSelectCorrect: (optionId: string) => void,
  onSelectIncorrect: () => void,
) => {
  const [quiz, setQuiz] = useState<GetCampaignQuizQuery['campaignQuiz']>();

  // コンポーネントの再レンダリングのたびにqueryが発行されると困るので、useGetCampaignQuizQueryは使わずに自前でclientのqueryを使う
  const client = useContext(Context) as Client;
  const [fetching, setFetching] = useState(false);
  const fetchQuiz = useCallback(() => {
    if (fetching) {
      return Promise.resolve();
    }

    setFetching(true);

    return client
      .query<GetCampaignQuizQuery>(GetCampaignQuizDocument, {
        id: quizId,
      })
      .toPromise()
      .then((result) => {
        setQuiz(result.data?.campaignQuiz);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [client, quizId, fetching]);

  const initializedOptions = useCallback(() => {
    return (
      quiz?.options.map((option) => ({
        id: option.id,
        text: option.text,
        isCorrect: option.isCorrect,
        selected: false,
      })) ?? []
    );
  }, [quiz]);

  const [optionsWithSelected, setOptionsWithSelected] = useState(
    initializedOptions(),
  );

  const selectOption = useCallback((idx: number) => {
    setOptionsWithSelected((prev) => {
      const newOps = [...prev];
      newOps[idx].selected = true;
      return newOps;
    });
  }, []);

  const resetOptions = useCallback(() => {
    setOptionsWithSelected(initializedOptions());
  }, [initializedOptions]);

  const onSelectOption = useCallback(
    (idx: number) => {
      selectOption(idx);
      const option = optionsWithSelected?.[idx];
      if (option?.isCorrect) {
        onSelectCorrect(option.id);
      } else {
        onSelectIncorrect();
      }
    },
    [onSelectCorrect, onSelectIncorrect, optionsWithSelected, selectOption],
  );

  // quizが更新されたらoptionsをリセットする
  useEffect(() => {
    resetOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quiz]);

  useEffect(() => {
    if (!fetching && quizId !== quiz?.id) {
      fetchQuiz();
    }
  }, [fetching, quiz, quizId, fetchQuiz]);

  return {
    fetchQuiz,
    quiz,
    options: optionsWithSelected,
    onSelectOption,
    resetOptions,
    fetching,
  };
};

export const useRecordAchieveQuiz = () => {
  const [{ data, fetching, error }, recordAchieveCampaignQuiz] =
    useAchieveCampaignQuizMutation();
  return {
    data,
    fetching,
    error,
    recordAchieveCampaignQuiz,
  };
};
