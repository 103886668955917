import 'dayjs/locale/ja';

import dayjs, { ConfigType, OpUnitType } from 'dayjs';
import advanceFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanceFormat);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

//MEMO:
// 'z' タイムゾーンの表記が地域によってはGMT+2のような表記になる
// アメリカの地域はESTなどの表記になるが、すべての地域が対応していなさそう
// 'ZZZ' Japanese Standard Timeと表示されるが長くなる

type Local = 'en' | 'ja';

function to(
  compared: ConfigType,
  from?: ConfigType,
  options?: {
    locale?: Local;
    withoutSuffix?: boolean;
  },
): string {
  const locale = options?.locale ?? 'ja';
  const withoutSuffix = options?.withoutSuffix ?? false;

  return dayjs(from).locale(locale).to(compared, withoutSuffix);
}

function diff(d0: ConfigType, d1?: ConfigType): number {
  return dayjs(d0).diff(d1 || dayjs());
}

function format(d: ConfigType, template = 'HH:mm A (MM/DD/YYYY) z'): string {
  return dayjs.utc(d).local().format(template);
}

function isAfter(d: ConfigType, unit?: OpUnitType): boolean {
  return dayjs().isAfter(d, unit);
}

function parseFormat(
  d: ConfigType,
  parse: string,
  template = 'HH:mm A (MM/DD/YYYY) z',
): string {
  return format(dayjs(d, parse), template);
}

export { diff, format, isAfter, parseFormat, to };
