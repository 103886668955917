export enum EnvErrorType {
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  UNDER_MAINTENANCE = 'UNDER_MAINTENANCE',
}

export const envErrorMessage: Record<string | EnvErrorType, string> = {
  [EnvErrorType.INTERNAL_SERVER_ERROR]:
    'システム内部でエラーが発生しました。この問題が何度も発生する場合は、運営までお問い合わせください。',
  [EnvErrorType.UNDER_MAINTENANCE]: '現在メンテナンス中です。',
};
