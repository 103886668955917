import { useCallback } from 'react';
import { OperationResult as UrqlOperationResult, UseMutationState } from 'urql';

import {
  AssignExchangeableItemMutation,
  Exact,
  useAssignExchangeableItemMutation,
} from '..';

type MutationState = UseMutationState<
  AssignExchangeableItemMutation,
  Exact<{
    campaignExchangeableItemId: string | number;
  }>
>;

type OperationResult = UrqlOperationResult<
  AssignExchangeableItemMutation,
  Exact<{
    campaignExchangeableItemId: string | number;
  }>
>;

export const useAssignExchangableItem = (
  applicationId: string,
): [
  MutationState,
  (campaignExchangeableItemId: string) => Promise<OperationResult>,
] => {
  const [state, exchangeItemMutation] = useAssignExchangeableItemMutation();

  const assignExchangeItem = useCallback(
    async (campaignExchangeableItemId: string) => {
      const state = await exchangeItemMutation(
        {
          applicationId,
          campaignExchangeableItemId,
        },
        { additionalTypenames: ['CampaignAchievemenLog'] },
      );

      return state;
    },
    [applicationId, exchangeItemMutation],
  );

  return [state, assignExchangeItem];
};

export default useAssignExchangableItem;
