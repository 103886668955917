import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';

import {
  createUrqlSSRClient,
  EnvErrorType,
  pickGraphqlCode,
  PingDocument,
} from '../';

export const isUnderMaintenance = async (): Promise<boolean> => {
  const client = createUrqlSSRClient(undefined, 'wallet');
  const result = await client.query(PingDocument, undefined).toPromise();
  const errorCode = pickGraphqlCode(result?.error);
  if (errorCode === EnvErrorType.UNDER_MAINTENANCE) {
    return true;
  }

  return false;
};

export function checkUnderMaintenance<T>(
  destination: string,
  next: (
    ctx: GetServerSidePropsContext,
  ) => Promise<GetServerSidePropsResult<T>>,
) {
  return async (
    ctx: GetServerSidePropsContext,
  ): Promise<GetServerSidePropsResult<T>> => {
    const underMaintenance = await isUnderMaintenance();
    if (underMaintenance) {
      return {
        redirect: {
          statusCode: 301,
          destination,
        },
      };
    }

    return next(ctx);
  };
}
