import {
  RouterWithProxy,
  useRouterWithProxy,
} from '@collection-platform-frontend/shared';

const proxyPaths = ['/', '/beginners'];

export type Router = RouterWithProxy;

export const useRouter = (): Router => {
  return useRouterWithProxy(proxyPaths);
};
