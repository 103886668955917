import { GetServerSidePropsContext } from 'next';
import absoluteUrl from 'next-absolute-url';
import { NextSeoProps } from 'next-seo';

import { GetCampaignDetailQuery } from '../graphql/schema';
import { getCampaign, isNotFoundCampaign } from './check-campaign';

const createSeoByQuest = (
  campaign: GetCampaignDetailQuery['campaign'],
  url: string,
): NextSeoProps => {
  const title = campaign.title;
  const description = campaign.description.slice(0, 100);
  return {
    title,
    description,
    openGraph: {
      url,
      title,
      description,
      images: [
        {
          url: campaign.bannerImageAsset?.url ?? '',
          alt: 'campaign-banner',
        },
      ],
    },
  };
};

export const getQuestSeoProps = async ({
  req,
  params,
  resolvedUrl,
}: GetServerSidePropsContext) => {
  const slug = params?.['slug'] as string;
  const result = await getCampaign(slug);
  if (isNotFoundCampaign(result)) {
    return {
      props: {
        seopData: null,
      },
    };
  }

  const { origin } = absoluteUrl(req);
  const campaign = result.data?.campaign;
  const metadata = campaign
    ? createSeoByQuest(campaign, origin + resolvedUrl)
    : null;

  return {
    props: {
      seoData: metadata,
    },
  };
};
