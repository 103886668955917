export enum CampaignErrorType {
  NOT_FOUND_CAMPAIGN = 'NOT_FOUND_CAMPAIGN',
  INVALID_ACHIEVEMENT_CODE = 'INVALID_ACHIEVEMENT_CODE',
  ALREADY_CAMPAIGN_MISSION_ACHIEVED = 'ALREADY_CAMPAIGN_MISSION_ACHIEVED',
}

export const campaignErrorMessage: Record<string | CampaignErrorType, string> =
  {
    [CampaignErrorType.NOT_FOUND_CAMPAIGN]:
      'キャンペーンが見つかりませんでした',
    [CampaignErrorType.INVALID_ACHIEVEMENT_CODE]: '不正なミッションコードです',
    [CampaignErrorType.ALREADY_CAMPAIGN_MISSION_ACHIEVED]:
      '既にミッションは達成されています',
  };
