import { DefaultSeoProps } from 'next-seo';

export const getOGPConfig = (
  override: DefaultSeoProps = {},
): DefaultSeoProps => {
  return {
    title: 'CLOUDEAR（クラウディア）',
    description:
      'CLOUDEAR（クラウディア）は、小学館が提供するデジタルコンテンツストア。お気に入りをもっと好きになるように、そして新しいお気に入りが見つかるように、あなたと作品をつなげていくための場所です。',
    openGraph: {
      title: 'CLOUDEAR（クラウディア）',
      images: [
        {
          url: 'images/ogp_image.png',
        },
      ],
    },
    ...override,
  };
};
