import { getAuth, ParsedToken } from 'firebase/auth';
import jwt_decode from 'jwt-decode';

export const getIdToken = async (forceRefresh?: boolean) => {
  const auth = getAuth();
  return await auth.currentUser?.getIdToken(forceRefresh);
};

export const isExpiredToken = (token?: string | null): boolean => {
  if (!token) {
    return false;
  }

  const tokenInfo = jwt_decode<ParsedToken>(token);
  if (!tokenInfo.exp) {
    return false;
  }

  const d = new Date();
  d.setTime(parseInt(tokenInfo.exp, 10) * 1000);
  return Date.now() > d.getTime();
};
