import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, ms = 1000) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const callbackRef = useRef(() => {});

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const timerId = setInterval(() => callbackRef.current(), ms);
    return () => clearInterval(timerId);
  }, [ms]);
};

export const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};
