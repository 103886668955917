import { useEffect, useState } from 'react';

import { AchievementIconData } from './types/mission';

export const useWatchBadgeAwardEvent = ({
  pointBalance,
  achievementIcons,
  onEvent,
}: {
  pointBalance?: number;
  achievementIcons?: AchievementIconData[];
  onEvent?: (icon: AchievementIconData) => void;
}) => {
  const [prePointBalance, setPrePointBalance] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    if (pointBalance === undefined) {
      return;
    }
    if (prePointBalance === undefined) {
      setPrePointBalance(() => pointBalance);
      return;
    }

    if (prePointBalance < pointBalance) {
      setPrePointBalance(() => pointBalance);

      const awardedIcons = (achievementIcons ?? []).filter(
        (icon) =>
          prePointBalance < icon.achievementPoint &&
          icon.achievementPoint <= pointBalance,
      );
      awardedIcons.forEach((icon) => {
        onEvent && onEvent(icon);
      });
    }
  }, [pointBalance, achievementIcons, onEvent, prePointBalance]);
};

export default useWatchBadgeAwardEvent;
