const TOKEN_KEY = process.env.NEXT_PUBLIC_TOKEN_NAME ?? 'token';

export const storeAuthToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getStoredToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const clearStoredToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};
