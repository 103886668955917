import http from 'http';
import https, { RequestOptions } from 'https';
import { GetServerSidePropsContext } from 'next';

export const reverseProxy = (
  { req, res }: GetServerSidePropsContext,
  options: RequestOptions,
  isSecure?: boolean,
): Promise<void> => {
  return new Promise((resolve) => {
    const serverReq = (isSecure ? https : http)
      .request(options)
      .on('error', () => res.writeHead(502).end())
      .on('timeout', () => res.writeHead(504).end())
      .on('response', (serverRes) => {
        res.writeHead(serverRes.statusCode ?? 200, serverRes.headers);
        serverRes.pipe(res);
      })
      .on('close', resolve);
    req.pipe(serverReq);
  });
};

export const reverseProxySSR = (path: string) => {
  return async (ctx: GetServerSidePropsContext) => {
    await reverseProxy(
      ctx,
      {
        host: process.env.WEBFLOW_HOST,
        path,
      },
      true,
    );

    return {
      props: {},
    };
  };
};
