import { GetServerSidePropsContext } from 'next';
import absoluteUrl from 'next-absolute-url';
import { NextSeoProps } from 'next-seo';

import { createUrqlSSRClient } from '../graphql/client';
import { GetNftDocument, GetNftQuery } from '../graphql/schema';

const fetchNft = async (id: string) => {
  const client = createUrqlSSRClient(undefined, 'wallet');
  const result = await client
    .query(GetNftDocument, {
      id,
    })
    .toPromise();

  return result;
};

const createSeoByNft = (nft: GetNftQuery['nft'], url: string): NextSeoProps => {
  const title = `${nft.item?.title} #${nft.serialNumber ?? '-'}`;
  const description = nft.item?.description.slice(0, 100);
  return {
    title,
    description,
    openGraph: {
      url,
      title,
      description,
      images: [
        {
          url: nft.item?.thumbnailAssetUrl ?? '',
          alt: 'nft-thumbnail',
        },
      ],
    },
  };
};

export const getNftSeoProps = async ({
  req,
  params,
  resolvedUrl,
}: GetServerSidePropsContext) => {
  const nftId = params?.['id'] as string;
  const result = await fetchNft(nftId);

  const { origin } = absoluteUrl(req);
  const nft = result.data?.nft;
  const metadata = nft ? createSeoByNft(nft, origin + resolvedUrl) : null;

  return {
    props: {
      seoData: metadata,
    },
  };
};
