export type ApiType = 'admin' | 'wallet';

const endpoints: Record<ApiType, string | undefined> = {
  wallet: process.env.NEXT_PUBLIC_WALLET_API_ENDPOINT,
  admin: process.env.NEXT_PUBLIC_APP_ADMIN_API_ENDPOINT,
};

export const getApiEndpoint = (type: ApiType = 'wallet'): string => {
  const endpoint = endpoints[type];
  if (!endpoint) {
    throw new Error(`not found endpoint from type (${type})`);
  }

  return endpoint;
};

const applicationId = process.env.NEXT_PUBLIC_X_COLLECTION_APPLICATION_ID;
const applicationSecret =
  process.env.NEXT_PUBLIC_X_COLLECTION_APPLICATION_SECRET;

export const getAuthHeaders = (
  token?: string | null,
): Record<string, string> => {
  return token
    ? {
        authorization: `Bearer ${token}`,
      }
    : {};
};

export const getCollectionHeaders = (
  token?: string | null,
): Record<string, string> => {
  return {
    ...(applicationId ? { 'x-collection-application-id': applicationId } : {}),
    ...(applicationSecret
      ? { 'x-collection-application-secret': applicationSecret }
      : {}),
    ...getAuthHeaders(token),
  };
};
